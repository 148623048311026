import { getStateData, loadState } from '@/store'
import { identifyUserInLaunchDarkly } from '@/services/launchDarkly'
import { getAuth0Header } from '@/services/tokenManager'
import { extractTenantFromToken, setTenant } from '@/plugins/tenants'
import startAsyncService from '@/services/retry-service'
import { identifyUserInAnalytics } from '@/services/analytics'
import { translationProvider } from '@/services/translationProvider'
import { identifyUserInTracking } from '@/services/tracking'

const LAUNCH_DARKLY_SERVICE_NAME = 'Launch Darkly'

type UserData = {
  onLogin: boolean
}

export const loadUserData = async ({ onLogin = false }: UserData) => {
  const authHeader = await getAuth0Header()
  if (!authHeader) {
    console.warn('[Otters shell - userDataHelper] - Undefined token')
    return
  }

  const tenant = extractTenantFromToken(authHeader)
  if (tenant) {
    setTenant(tenant)
  }

  if (!(await loadState())) {
    return
  }

  identifyUserInAnalytics({ onLogin })

  startAsyncService(LAUNCH_DARKLY_SERVICE_NAME, identifyUserInLaunchDarkly)

  await translationProvider.set(getStateData().language)
  identifyUserInTracking()
}
