import '@/assets/tailwind.global.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { loadUserData } from '@/store'
import { initAuth0 } from '@sennder/senn-node-auth0-frontend'
import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend'
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_MOTHERSHIP_AUDIENCE,
  LAUNCH_DARKLY_KEY,
} from '@/common/config'
import { registerPlugins } from './plugins'
import { logger } from '@/services/logger/loggers'
import { registerRoutesAndMiddlewares } from './router/routes'
import { cookieConsentService } from '@/services/CookieConsentService'
import { startTracking } from '@/services/tracking'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { translationProvider } from '@/services/translationProvider'

async function initialize() {
  if (!AUTH0_CLIENT_ID) {
    throw new Error('AUTH0_CLIENT_ID not found')
  }
  if (!AUTH0_DOMAIN) {
    throw new Error('AUTH0_DOMAIN not found')
  }

  await initAuth0({
    client_id: AUTH0_CLIENT_ID,
    domain: AUTH0_DOMAIN,
    audience: AUTH0_MOTHERSHIP_AUDIENCE,
    cacheLocation: 'localstorage',
  })

  // OPTIONAL: Initialize all data and services here that are independent from token or user data
  if (!LAUNCH_DARKLY_KEY) {
    throw new Error('LAUNCH_DARKLY_KEY not found')
  }
  await initLaunchDarkly(LAUNCH_DARKLY_KEY, getLaunchDarklyAnonymousContext())

  await translationProvider.initialize()
  await loadUserData({ onLogin: false })
}

function checkCookieConsent() {
  try {
    const consent = cookieConsentService.didConsentAll
    if (consent) {
      startTracking()
    }
  } catch (error) {
    logger.error(
      `[otters-shell - checkCookieConsent] Error checking cookie consent`,
      { error }
    )
  }
}

async function start() {
  checkCookieConsent()

  try {
    await initialize()
  } catch (error) {
    logger.error(`Otters shell init`, { error })
  } finally {
    const app = createApp(App)
    registerRoutesAndMiddlewares(router)
    app.use(router)
    registerPlugins(app)

    app.mount('#app')
  }
}

start()
