import { parseTenantUrl } from '@/plugins/tenants'
import { Tenant } from '@sennder/senn-node-microfrontend-interfaces'

// Auth0 config
const auth0ClientIds = {
  [Tenant.SENNDER]: process.env.VUE_APP_AUTH0_CLIENT_ID,
  [Tenant.POSTE]: process.env.VUE_APP_AUTH0_CLIENT_ID_POSTE,
  [Tenant.DEMO]: process.env.VUE_APP_AUTH0_CLIENT_ID_DEMO,
}

// Mothership URL config
const mothershipUrls = {
  [Tenant.SENNDER]: process.env.VUE_APP_MOTHERSHIP_URL,
  [Tenant.POSTE]: process.env.VUE_APP_MOTHERSHIP_URL_POSTE,
  [Tenant.DEMO]: process.env.VUE_APP_MOTHERSHIP_URL_DEMO,
}

const activeTenant = parseTenantUrl()

export const ENV = process.env.VUE_APP_ENV as 'dev' | 'prod' | 'uat'
export const APP_VERSION = process.env.VUE_APP_VERSION
export const MOTHERSHIP_URL =
  mothershipUrls[activeTenant as keyof typeof mothershipUrls]
export const SPS_URL = `${process.env.VUE_APP_API_GATEWAY_URL}/shipper-profile`
export const SUS_URL = `${process.env.VUE_APP_API_GATEWAY_URL}/shipper-user-service`
export const LAUNCH_DARKLY_KEY = process.env.VUE_APP_LAUNCH_DARKLY_KEY
export const LD_USER_STATIC_KEY = process.env.VUE_APP_LD_USER_STATIC_KEY
export const DATADOG_APP_ID = process.env.VUE_APP_DATADOG_APP_ID
export const DATADOG_CLIENT_TOKEN = process.env.VUE_APP_DATADOG_CLIENT_TOKEN
export const AMPLITUDE_API_KEY = process.env.VUE_APP_AMPLITUDE_API_KEY
export const GTM_CONTAINER_ID = process.env.VUE_APP_GTM_CONTAINER_ID
export const TRANSLATIONS_URL = process.env.VUE_APP_TRANSLATIONS_URL

export const IS_LOCALHOST = window.location.hostname === 'localhost'

export const AUTH0_USE_TOKEN_POPUP = ENV !== 'prod'
export const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
export const AUTH0_MOTHERSHIP_AUDIENCE =
  process.env.VUE_APP_AUTH0_MOTHERSHIP_AUDIENCE
export const AUTH0_SPS_AUDIENCE = process.env.VUE_APP_AUTH0_SPS_AUDIENCE
export const AUTH0_SUS_AUDIENCE = process.env.VUE_APP_AUTH0_SUS_AUDIENCE
export const AUTH0_CLIENT_ID =
  auth0ClientIds[activeTenant as keyof typeof auth0ClientIds]

export const isLocalEnv = (): boolean => {
  return ENV === 'dev'
}

export function isUatEnv() {
  return ENV === 'uat'
}

export function isProductionEnv() {
  return ENV === 'prod'
}

export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN
export const FULLSTORY_ORGID = process.env.VUE_APP_FULLSTORY_ORGID
export const PERMISSIONS_SERVICE_URL =
  process.env.VUE_APP_PERMISSIONS_SERVICE_URL
